import axiosInstance from "./AxiosInstance";

export async function loginAuth(email: string, password: string) {
	return axiosInstance
		.post(`/sign-in`, { email, password })
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});
}

export async function getActualUserInfo(userId: string, userToken: string) {
	return axiosInstance
		.get(`/users/${userId}`)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});
}

export async function sendCodePassword(email: string) {
	const res = await axiosInstance.post("/sendPasswordCode", { email: email });
	return res.data;
}

export async function verifyResetPasswordCode(email: string, code: string) {
	const res = await axiosInstance.post("/verifyResetPasswordCode", { email, code });
	return res.data;
}

export async function setNewPassword(newPassword: string, token: string) {
	const res = await axiosInstance.post("/setNewPassword", { newPassword, token });
	return res.data;
}
