import AvailableClassCard from "@src/components/AvailableClassCard/AvailableClassCard";
import "./AvailableClasses.scss";
import { ReactComponent as RightArrow } from "@assets/icons/next-arrow-right.svg";
import { ReactComponent as RightArrowFilled } from "@assets/icons/next-arrow-right-filled.svg";
import { ReactComponent as LeftArrow } from "@assets/icons/next-arrow-left.svg";
import { ReactComponent as LeftArrowFilled } from "@assets/icons/next-arrow-left-filled.svg";
import { useEffect, useRef, useState } from "react";
import { useClassesContext } from "@src/context/ClassesContext";
import SpinnerCustom from "@src/components/SpinnerCustom/SpinerCustom";
import ScheduleClassModal from "@src/components/ScheduleClassModal/ScheduleClassModal";
import ScheduleWaitingListModal from "@src/components/ScheduleWaitingListModal/ScheduleWaitingListModal";
import LeaveWaitingListModal from "@src/components/LeaveWaitingListModal/LeaveWaitingListModal";
import DatePicker from "react-date-picker";
import { es } from "date-fns/locale";
import CancelClassModal from "@src/components/CancelClassModal/CancelClassModal";

const locationMap = [
	"PILATES REFORMER MORELOS",
	"PILATES REFORMER NAVARRETE",
	"POWER PILATES NAVARRETE",
	"POWER PILATES MORELOS",
	"RIO SONORA",
	"BARRE POINT MORELOS",
	"BARRE POINT NAVARRETE",
];

const locations = [
	"PILATES REFORMER MORELOS",
	"PILATES REFORMER NAVARRETE",
	"POWER PILATES NAVARRETE",
	"POWER PILATES MORELOS",
	"RIO SONORA",
	"BARRE POINT MORELOS",
	"BARRE POINT NAVARRETE",
];

function AvailableClasses() {
	const [location, setLocation] = useState(0);
	const [activeIndex, setActiveIndex] = useState<number>(0);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [datePicked, setDatePicked] = useState<any>();
	const [scheduleClassModal, setScheduleClassModal] = useState({
		show: false,
		clase: null,
	});
	const [scheduleWaitingListModal, setScheduleWaitingListModal] = useState({
		show: false,
		clase: null,
	});
	const [leaveWaitingListModal, setLeaveWaitingListModal] = useState({
		show: false,
		clase: null,
	});
	const { getClasses } = useClassesContext();

	const locationNavRef: any = useRef(null);

	const { nextClasses, loading } = useClassesContext();

	const handlePrevDate = () => {
		if (activeIndex > 0) {
			setActiveIndex(activeIndex - 1);
		}
	};

	const handleNextDate = () => {
		if (activeIndex < nextClasses.length - 1) {
			setActiveIndex(activeIndex + 1);
		}
	};

	const isDateInNextClasses = (date: any) => {
		return nextClasses.some(({ id }: any) => new Date(id).getTime() === date.getTime());
	};

	const handleFilter = (e: any) => {
		return e.location === locationMap[location];
	};

	const handleDateChange = (date: any) => {
		const index = nextClasses.findIndex(({ id }: any) => new Date(id).getTime() === date.getTime());

		if (index >= 0) {
			setActiveIndex(index);
		} else {
			// date is not in array, handle as needed
		}
	};

	useEffect(() => {
		function handleClickOutside(event: any) {
			if (locationNavRef.current && locationNavRef.current.contains(event.target)) {
				// User clicked outside of the menu
				setIsOpen(true);
			} else {
				setIsOpen(false);
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [locationNavRef]);

	useEffect(() => {
		setIsOpen(false);
	}, [location]);

	return (
		<div className="available-classes-main-container">
			<LeaveWaitingListModal
				show={leaveWaitingListModal.show}
				onHide={() => setLeaveWaitingListModal({ show: false, clase: null })}
				clase={leaveWaitingListModal.clase}
				getClasses={getClasses}
			/>
			<ScheduleClassModal
				show={scheduleClassModal.show}
				onHide={() => setScheduleClassModal({ show: false, clase: null })}
				clase={scheduleClassModal.clase}
			/>
			<ScheduleWaitingListModal
				show={scheduleWaitingListModal.show}
				onHide={() => setScheduleWaitingListModal({ show: false, clase: null })}
				clase={scheduleWaitingListModal.clase}
			/>
			<ul className={`location-filter-container ${isOpen ? " isOpen" : ""}`} ref={locationNavRef}>
				{locations.map((loc, index) => {
					return (
						<li
							role="button"
							className={location === index ? "active" : ""}
							onClick={() => setLocation(index)}
							key={loc}
						>
							{loc}
						</li>
					);
				})}
			</ul>
			{loading ? (
				<SpinnerCustom />
			) : (
				<div className="calendary-container">
					<div className="calendar-nav">
						{activeIndex === 0 ? (
							<LeftArrow role="button" />
						) : (
							<LeftArrowFilled role="button" onClick={handlePrevDate} />
						)}
						<DatePicker
							className={"custom-dp"}
							onChange={(date) => handleDateChange(date)}
							value={nextClasses[activeIndex]?.id}
							clearIcon={null}
							locale={"es-ES"}
							maxDetail={"month"}
							format="d/M/yyyy"
							tileDisabled={({ date, view }) => view === "month" && !isDateInNextClasses(new Date(date))}
						/>
						{activeIndex < nextClasses.length - 1 ? (
							<RightArrowFilled role="button" onClick={handleNextDate} />
						) : (
							<RightArrow />
						)}
					</div>
					<div className="classes-container">
						{nextClasses[activeIndex]?.data
							.filter((e: any) => handleFilter(e))
							.map((classAvailable: any) => {
								return (
									<AvailableClassCard
										class={classAvailable}
										setScheduleClassModal={setScheduleClassModal}
										setScheduleWaitingListModal={setScheduleWaitingListModal}
										setLeaveWaitingListModal={setLeaveWaitingListModal}
										key={classAvailable.id}
									/>
								);
							})}
					</div>
				</div>
			)}
		</div>
	);
}

export default AvailableClasses;
