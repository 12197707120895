import "./Login.scss";
import wellnessLogo from "@assets/logos/wellness-logo.png";
import { useFormik } from "formik";
import { useState } from "react";
import { object, string } from "yup";
import { ReactComponent as Eye } from "@assets/icons/eye.svg";
import { ReactComponent as EyeOff } from "@assets/icons/eye-off.svg";
import { ReactComponent as Email } from "@assets/icons/mail.svg";
import { ReactComponent as Lock } from "@assets/icons/lock.svg";
import { useUserContext } from "@src/context/UserContext";
import { notifyError } from "@src/utils/NotificationFunctions";
import { Link } from "react-router-dom";

const loginSchema = object({
	email: string().email("Correo no valido").required("Ingresa un correo"),
	password: string().required("Ingresa una contraseña"),
});

function Login() {
	const [loading, setLoading] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const { login } = useUserContext();

	const formik = useFormik({
		initialValues: {
			captcha: "",
			email: "",
			password: "",
		},
		validationSchema: loginSchema,
		onSubmit: async (values, { resetForm }) => {
			setLoading(true);
			login(values.email, values.password).then((res: any) => {
				if (res && res.response) {
					notifyError(res.response.data.message);
					setLoading(false);
				} else {
					setLoading(false);
				}
			});
		},
	});

	const { errors, touched } = formik;

	return (
		<div className="login-form-container">
			<img className="logo" src={wellnessLogo} alt="wellness logo" />
			<h2>¡Bienvenido a Wellness!</h2>
			<form className="login-form" onSubmit={formik.handleSubmit}>
				<div className="form-row">
					<label htmlFor="email">Correo</label>
					<div className="input-container">
						<label htmlFor="email">
							<Email />
						</label>
						<input
							id="email"
							name="email"
							type="email"
							placeholder="Correo"
							onChange={formik.handleChange}
							value={formik.values.email}
						/>
					</div>
					{touched.email && errors.email && <div className="form-error">{errors.email}</div>}
				</div>
				<div className="form-row">
					<label htmlFor="password">Contraseña</label>
					<div className="input-container">
						<label htmlFor="password">
							<Lock />
						</label>
						<input
							id="password"
							name="password"
							type={!showPassword ? "password" : "text"}
							placeholder="Contraseña"
							onChange={formik.handleChange}
							value={formik.values.password}
						/>
						{showPassword ? (
							<Eye className="showPassword" onClick={() => setShowPassword(!showPassword)} />
						) : (
							<EyeOff className="showPassword" onClick={() => setShowPassword(!showPassword)} />
						)}
					</div>
					{touched.password && errors.password && <div className="form-error">{errors.password}</div>}
				</div>
				<div className="form-row">
					<button type="submit" className={"login-submit " + (loading ? "loading" : "")} disabled={loading}>
						Iniciar sesión
					</button>
				</div>
				<div className="form-row">
					<button
						type="button"
						className={"login-submit"}
						onClick={() => (window.location.href = "https://wellness-studio.mx/register")}
					>
						Registrarme
					</button>
				</div>
			</form>
			<Link to={"reestablecer"}>¿Olvidaste tú contraseña?</Link>
		</div>
	);
}

export default Login;
