import { format } from "date-fns";
import "./UserInformationCard.scss";
import { IUser } from "@src/Interfaces/User";

type UserInformationCardProps = {
	user: IUser;
};

function UserInformationCard({ user }: UserInformationCardProps) {
	console.log(user.metadata.injury);
	return (
		<div className="user-additional-information-card">
			<div className="info-container general">
				<div className="row">
					<h3>INFORMACIÓN GENERAL</h3>
				</div>
				<div className="row">
					<p>
						<b>Miembro desde: </b> {format(new Date(user.createdAt), "dd/MM/yyyy")}
					</p>
				</div>
			</div>
			{user.metadata && (
				<div className="info-container emergency">
					<div className="row">
						<p>
							<b>Tipo de sangre: </b>
							{user.metadata?.bloodType}
						</p>
					</div>
					<div className="row">
						<p>
							<b>Alergias: </b>
							{user.metadata?.allergies}
						</p>
					</div>
					<div className="row">
						<p>
							<b>Embarazo: </b>
							{user.metadata?.pregnancy}
						</p>
					</div>
					<div className="row">
						<p>
							<b>Lesión: </b>
							{user.metadata?.injury}
						</p>
					</div>
					<hr />
					<div className="row">
						<h3>EMERGENCIAS</h3>
					</div>
					<div className="row">
						<p>
							<b>Contacto: </b>
							{user.metadata?.emergencyContact}
						</p>
					</div>
					<div className="row">
						<p>
							<b>Celular: </b>
							{user.metadata?.emergencyContactPhone}
						</p>
					</div>
				</div>
			)}
		</div>
	);
}

export default UserInformationCard;
