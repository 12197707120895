import React from "react";
import wellnessLogin from "@assets/images/login.jpg";
import "./AuthLayout.scss";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {
	return (
		<div className="auth-layout-main-container">
			<div className="portrait-image-container">
				<img src={wellnessLogin} alt="teacher" />
			</div>
			<Outlet />
		</div>
	);
};

export default AuthLayout;
