import { SetStateAction, useEffect, useState } from "react";
import "./Step1.scss";
import ItemCard from "./components/ItemCard";
import Step2 from "../Step2/Step2";
import BaseModal from "@src/components/BaseModal/BaseModal";
import { LessonI, PlanI } from "@src/Interfaces/Lesson";
import { returnLessonImage } from "@src/utils/CommonFunctions";
import Step3 from "../Step3/Step3";
import { number, object, string } from "yup";
import { useFormik, useFormikContext } from "formik";
import { notifyError, notifySuccess, procesarErrorOpenPay } from "@src/utils/NotificationFunctions";
import axios from "axios";
import { useUserContext } from "@src/context/UserContext";
import { createCharge } from "@src/services/OpenPay";
import { fetchLocationById, newMembership } from "@src/services/Lessons";
import Summary from "./components/Summary";
import { LocationI, PaymentProvider } from "@src/Interfaces/Locations";
import { paymentGateways } from "@src/utils/PaymentGateways";
import AcceptedCards from "./components/AcceptedCards/AcceptedCards";

declare var OpenPay: any;

interface StepsInterfacePropsI {
	step: number;
	setStep: React.Dispatch<SetStateAction<number>>;
	lessons: Array<PlanI>;
}

const CardSchema = object({
	cardNumber: string().required("Ingresa una tarjeta valida"),
	cardHolder: string().required("Ingresa un nombre"),
	cardExpiration: string().required("Campo obligatorio"),
	cardCvv: string().required("Campo obligatorio"),
});

const replaceWords = ["PILATES REFORMER ", "BARRE POINT ", "POWER PILATES "];

// Se pensaba utilizar un componente por paso pero al final obte por utilizar
// este solo componente para gestionar todo el checkout
function Step1({ step, setStep, lessons }: StepsInterfacePropsI) {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [plansSelected, setPlansSelected] = useState<Array<PlanI>>([]);
	const [deviceId, setDeviceId] = useState<any>();
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const { user, userToken } = useUserContext();
	const [paymentInfo, setPaymentInfo] = useState();
	const [paymentProvider, setPaymentProvider] = useState<PaymentProvider | null>(null);

	const handlePlan = async (plan: PlanI) => {
		const tempPlans = [...plansSelected];
		const index = tempPlans.findIndex((e) => e.id === plan.id);

		if (index === -1) {
			tempPlans.push({ ...plan, pricingIndex: 0, locationId: "" });
		} else {
			tempPlans.splice(index, 1);
		}

		setPlansSelected(tempPlans);
	};

	// En esta función se selecciona el precio del plan seleccionado
	const handlePricing = async (plan: PlanI, index: number) => {
		const tempPlans = [...plansSelected];
		const planIndex = tempPlans.findIndex((e) => e.id === plan.id);

		if (planIndex !== -1) {
			tempPlans[planIndex].pricingIndex = index;
		}

		setPlansSelected([...tempPlans]);
	};

	const handleLocationByPlan = (newLocation: LocationI, planId: string) => {
		const tempPlans = [...plansSelected];
		const planIndex = tempPlans.findIndex((e) => e.id === planId);

		if (planIndex !== -1) {
			console.log(newLocation);
			tempPlans[planIndex].locationId = newLocation.id;
			setPaymentProvider(newLocation.paymentProvider);
		}

		setPlansSelected(tempPlans);
	};

	function getTotal() {
		let total = 0;
		plansSelected.map((plan) => {
			total += parseInt(plan.lessons.pricing[plan.pricingIndex].cost);
		});

		return total.toFixed(2);
	}

	const completeOrder = () => {
		if (plansSelected.length === 0) {
			return notifyError("Es necesario que selecciones un plan");
		}

		const allLocationsNotEmpty = plansSelected.every((p) => p.locationId);

		if (!allLocationsNotEmpty) {
			return notifyError("Es necesario que selecciones una sucursal para cada uno de tus planes");
		}

		setStep(2);
	};

	const formik = useFormik({
		initialValues: {
			cardNumber: "",
			cardHolder: "",
			cardExpiration: "",
			cardCvv: "",
		},
		validationSchema: CardSchema,
		onSubmit: async (values) => {
			setLoadingSubmit(true);
			const exp_month = values.cardExpiration.substring(0, 2);
			const exp_year = values.cardExpiration.substring(3, 5);
			const card = {
				card_number: values.cardNumber.replaceAll(" ", ""),
				holder_name: values.cardHolder,
				expiration_year: exp_year,
				expiration_month: exp_month,
				cvv2: values.cardCvv,
				device_session_id: deviceId,
			};

			let description = "";

			await Promise.all(
				plansSelected.map(async (plan) => {
					try {
						const location = await fetchLocationById(plan.locationId);
						const regex = new RegExp(replaceWords.join("|"), "gi");

						const locationName = location.name.replace(regex, () => {
							return "";
						});
						description += `${plan.lessons.pricing[plan.pricingIndex].reservations} reservas sueltas de ${
							plan.name
						} - Sucursal: ${locationName}.\n`;
					} catch (error) {
						console.error(error);
					}
				})
			);
			if (paymentProvider) {
				await axios({
					method: "post",
					url: `${process.env.REACT_APP_OPENPAY_URL}/${paymentGateways[paymentProvider.name].id}/tokens`,
					auth: {
						username: `${paymentGateways[paymentProvider.name].public}`,
						password: "",
					},
					headers: {
						"Content-type": "application/json",
					},
					data: {
						...card,
					},
				})
					.then(async (res) => {
						const cardData = res.data;
						const chargeData = {
							method: "card",
							currency: "MXN",
							source_id: cardData.id,
							amount: parseFloat(getTotal()),
							description: description || "Pago de membresía",
							device_session_id: deviceId,
							customer: {
								name: user.name,
								last_name: user.lastname,
								phone_number: user.phone,
								email: user.email,
							},
							ppi: paymentProvider.id,
						};

						await createCharge(chargeData, userToken).then(async (res) => {
							if ("error" in res || (res.details && res.details.error_code)) {
								notifyError(procesarErrorOpenPay(res.details.error_code));
								setLoadingSubmit(false);
							} else {
								setPaymentInfo(res);
								await Promise.all(
									plansSelected.map(async (plan) => {
										const planData = {
											userId: user.id,
											planId: "230ca849-f427-41bd-b690-9935ab17f616",
											startDate: new Date(),
											freeClass: plan.lessons.pricing[plan.pricingIndex].reservations,
											duration: 1,
											typeDuration: "month",
											classId: plan.lessons.id,
											locationId: plan.locationId,
											payment: {
												amount: plan.lessons.pricing[plan.pricingIndex].cost,
												paymentMethod: cardData.card.type,
												noReference: res.id,
												metadata: {
													...res,
												},
											},
										};

										await newMembership(planData, userToken).then((res) => {
											if (res.error) {
												notifyError("Ha ocurrido un error");
											} else {
												notifySuccess("Se ha generado correctamente tu membresía de " + plan.name);
											}
										});
									})
								);
								setLoadingSubmit(false);
								setStep(3);
							}
						});
					})
					.catch((error) => {
						console.error(error);
						notifyError(
							"Ha ocurrido un error, verifica los datos proporcionados ó su conexión a internet e intente de nuevo."
						);
						setLoadingSubmit(false);
					});
			} else {
				notifyError("No es posible realizar el cobro en este momento.");
			}
		},
	});

	useEffect(() => {
		if (step === 2 && paymentProvider) {
			console.log(paymentProvider.name);
			OpenPay.setId(paymentGateways[paymentProvider.name].id);
			OpenPay.setApiKey(paymentGateways[paymentProvider.name].public);
			OpenPay.setSandboxMode(process.env.REACT_APP_ENVIRONMENT === "dev" ? true : false);
			setDeviceId(OpenPay.deviceData.setup("payment-form"));
		}
	}, [step, paymentProvider]);

	if (step === 3) return <Step3 paymentInfo={paymentInfo} />;

	return (
		<form onSubmit={formik.handleSubmit} className="purchase-steps step1" id="payment-form">
			<BaseModal title="Planes / servicios" show={openModal} onHide={() => setOpenModal(false)}>
				<div className="lesson-selector-container border rounded">
					{lessons.map((plan) => {
						return (
							<div className="lesson-item border-bottom d-flex align-items-center p-3" key={plan.id}>
								<div className="lesson-image-container">{returnLessonImage(plan.name)}</div>
								<div className="flex-grow-1">
									<h3 className="fs-14 fw-bolder">{plan.name}</h3>
								</div>
								{plansSelected.findIndex((e) => e.id === plan.id) === -1 ? (
									<button type="button" className="btn primary fs-14" onClick={() => handlePlan(plan)}>
										+ Agregar
									</button>
								) : (
									<button type="button" className="btn danger fs-14" onClick={() => handlePlan(plan)}>
										- Quitar
									</button>
								)}
							</div>
						);
					})}
				</div>
			</BaseModal>
			<div className="plans-container">
				<div
					className="promomix-container primary-bg"
					onClick={() =>
						window.open("https://api.whatsapp.com/send?phone=526624171867&text=¡Hola! Quiero más información")
					}
				>
					<p>
						<b>¡HAZ TU MIX DE MEMBRESIAS!:</b> Compra una membresía de PILATES REFORMER y una de POWER
						PILATES/BARRE POINT y obtén el 15% en la membresía de menor costo. (Válido solo
						pagando en sucursal).
					</p>
				</div>
				{step === 1 && (
					<div className="cart-container">
						<div className="header">
							<h3>Carrito ({plansSelected.length})</h3>
							<button className="btn primary" onClick={() => setOpenModal(!openModal)} type="button">
								+ Agregar membresía
							</button>
						</div>
						<div className="items-container">
							{plansSelected.map((plan) => {
								return (
									<ItemCard
										handlePricing={handlePricing}
										handlePlan={handlePlan}
										plan={plan}
										handleLocationByPlan={handleLocationByPlan}
										key={plan.id}
									/>
								);
							})}
						</div>
					</div>
				)}
				{step === 2 && (
					<>
						<Step2 formik={formik} />
					</>
				)}
			</div>
			<Summary
				getTotal={getTotal}
				step={step}
				loadingSubmit={loadingSubmit}
				completeOrder={completeOrder}
				plansSelected={plansSelected.length}
			/>
		</form>
	);
}

export default Step1;
