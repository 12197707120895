import { isAxiosError } from "axios";
import { notifyError } from "./NotificationFunctions";

export function handleError(error: unknown) {
	if (isAxiosError(error)) {
		const message = error.response?.data?.message || "Error en la solicitud.";
		notifyError(message);
	} else {
		console.error("Unexpected error:", error);
		notifyError("Ocurrió un error inesperado.");
	}
}
